import React, { useEffect, useState } from 'react';
import "./Home.scss"
import axios from 'axios';


import mockup from "../../assets/images/mockup.png"
import gsap from 'gsap';
// import badgeApple from "../../assets/images/apple-store-badge.svg"
// import badgeGoogle from "../../assets/images/google-play-badge.png"



const Home = () => {

    const [email, setEmail] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        const punchlineTimeline = gsap.timeline();

        // intro line anim
        gsap.utils.toArray('.punchline span').forEach((span, index) => {
            punchlineTimeline.to(span, {
                duration: 0.2,
                opacity: 1,
                ease: "power4.out",
            });
        });

        // badges anim
        // gsap.fromTo(".badges img", {
        //     opacity: 0
        // }, {
        //     delay: 2,
        //     opacity: 1,
        //     ease: "sine.out"
        // })

        // beta anim
        gsap.fromTo(".beta-form", {
            opacity: 0
        }, {
            delay: 2,
            opacity: 1,
            ease: "sine.out"
        })

        // mockup anim
        gsap.fromTo(".mockup", {
            opacity: 0,
            y: 0
        }, {
            delay: 2.2,
            opacity: 1,
            ease: "sine.out",
            y: -40
        })

    }, []);

    const handleWaitlistSubmit = async (e) => {

        e.preventDefault();

        if (!email.trim()) {
            return;
        }

        try {

            await axios.post(process.env.REACT_APP_API_URL_PROD, { email });

            setEmail('');
            setIsSubmitted(true);
        } catch (error) {
            console.error('Error submitting form:', error);
            setIsSubmitted(false);
        }
    }


    return (
        <div className='home'>
            <div className='container'>
                <div className='punchline'>
                    <span>archo</span> <span>is</span> <span>a</span> <span>minimalist,</span> <span>all-in-one</span> <span>tool</span> <span>for</span> <span>architecture</span> <span>firms.</span>
                </div>

                {/* <div className='badges'>
                        <img src={badgeApple} />
                        <img src={badgeGoogle} width={150} />
                    </div> */}

                {isSubmitted ? (
                    <>
                        <div className="submission">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M9 16.2l-3.5-3.5L4 14l5 5 11-11-1.4-1.4L9 16.2z" />
                            </svg>
                            <span>You're in!</span>
                        </div>
                    </>
                ) : (
                    <form className='beta-form' onSubmit={handleWaitlistSubmit} >
                        <input
                            placeholder='jane@janearchitects.com'
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                        />
                        <button type="submit">join the waitlist</button>
                    </form>
                )}

                <img src={mockup} className='mockup' width={240} height={"auto"} alt='archo-mockup' />
            </div>
        </div>
    )
}

export default Home