import React from 'react'
import "./Footer.scss"

const Footer = () => {
    return (
        <div className='footer'>
            <p className='copyright'>archo © 2024.</p>
        </div>
    )
}

export default Footer
