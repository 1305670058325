import React from "react";
import "./Header.scss";


import x from "../../assets/images/x.avif";
import ig from "../../assets/images/ig.png";

const Header = () => {
    return (
        <div className="header">
            <ul className="navbar-left">
                <li>archo</li>
            </ul>
            <ul className="navbar-right">
                <li><img src={x} alt="twitter logo" width={30} /></li>
                <li><a href="https://www.instagram.com/getarcho" target="_blank"><img src={ig} alt="instagram logo" width={20} /></a></li>
            </ul>
        </div>
    )
}

export default Header
